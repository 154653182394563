import React from 'react';
import { withRouter } from 'react-router-dom';
import { SideNavigation } from '../aws-ui-components';

// Component ServiceNavigation is the Side Navigation component that is used in BasicLayout, CreateForm, ServiceHomepage, and Table flows.
// Implement like this: <ServiceNavigation />
function ServiceNavigation(props) {
  // If the provided link is empty, do not redirect pages
  function onFollowHandler(ev) {
    ev.preventDefault();
    if (ev.detail.href) {
      props.history.push(ev.detail.href.substring(1));
    }
  }

  return (
    <SideNavigation
      header={{ text: 'Amazon DRS', href: '#/' }}
      items={items}
      activeHref={`#${props.location.pathname}`}
      onFollow={onFollowHandler}
    />
  );
}

const items = [
  {
    type: 'section',
    text: 'Data release',
    items: [
      { type: 'link', text: 'Documents', href: '#/table' },
      { type: 'link', text: 'Workflows', href: '#/workflow' },
    ]
  },
  {
    type: 'section',
    text: 'Additional resources',
    items: [
      { type: 'link', text: 'CodeCommit repo', href: '' },
      { type: 'link', text: 'Help page', href: '' }
    ]
  }
];

export default withRouter(ServiceNavigation);
