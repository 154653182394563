/************************************************************************
                            DISCLAIMER

This is just a playground package. It does not comply with best practices
of using AWS-UI components. For production code, follow the integration
guidelines: 

https://polaris.corp.amazon.com/getting_started/development/integration/
************************************************************************/
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import BobRossIntro from './BobRossIntro.jsx';
import Basic from './BasicLayout.jsx';
import Workflow from './BasicWorkflow.jsx';
import TableWorkflow from './TableWorkflow.jsx'
import ServiceHomepage from './ServiceHomepage.jsx';
import CreateForm from './CreateForm.jsx';
import TableView from './Table.jsx';

import '@amzn/awsui-global-styles/polaris.css';

// Class App is the "output" generated on every build,
// it is what you will see on the webpage.
export default class App extends React.Component {
  render() {
    return (
      // When you create a new file or template, add it below
      // as a new 'Route' so you can link to it with a url.

      <div>
        <Switch>
          <Route exact path="/" component={ServiceHomepage} />
          <Route path="/table/:key" component={Basic} />
          <Route path="/table" component={TableView} />
          <Route path="/workflow/:key" component={Workflow} />
          <Route path="/workflow" component={TableWorkflow} />
          <Route path="/create" component={CreateForm} />
          
        </Switch>
      </div>
    );
  }
}
