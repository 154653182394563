/************************************************************************
                            DISCLAIMER

This is just a playground package. It does not comply with best practices
of using AWS-UI components. For production code, follow the integration
guidelines:

 https://polaris.a2z.com/develop/integration/react/
************************************************************************/
import React, { useState, useEffect } from 'react';
import { Storage } from 'aws-amplify'
import ServiceNavigation from './ServiceNavigation.jsx';
import {
  AppLayout,
  AttributeEditor,
  BreadcrumbGroup,
  Button,
  Checkbox,
  ColumnLayout,
  ExpandableSection,
  Form,
  FormField,
  Input,
  Multiselect,
  RadioGroup,
  Select,
  Textarea,
  Tiles,
  Header,
  SpaceBetween,
  Container,
  Link,
  HelpPanel,
  Box,
  StatusIndicator,
  Popover,
  Icon
} from '../aws-ui-components';
import axios from 'axios';

import '../styles/form.css';

// Component Basic is a skeleton of the basic App layout using AWS-UI React components.
export default function Workflow({ match }) {

  let fileName = match.params.key

  const [image, setImage] = useState([''])
  const [approval, setApproval] = useState(['PENDING'])
  const [time, setTime] = useState([''])
  const [created, setCreated] = useState([''])
  const [email, setEmail] = useState([''])
  const [approved, setApproved] = useState(false)

  const retrieveWorkflow = () => {
    const api = 'https://3v6vw2ilwh.execute-api.us-east-1.amazonaws.com/dev/getall';
    axios.post(api, { "file_name": `public/${fileName}` }
    )
      .then((response) => {
        console.log(response)
        setApproval(response.data["Items"][0]["approval"]["S"])
        setTime(response.data["Items"][0]["responsedate"]["S"])
        setEmail(response.data["Items"][0]["Email"]["S"])
        setCreated(response.data["Items"][0]["uploaddate"]["S"])
        if (response.data["Items"][0]["approval"]["S"] === "APPROVED") {
          setApproved(true)
        } else {
          setApproved(false)
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    retrieveWorkflow()

    const api2 = 'https://3v6vw2ilwh.execute-api.us-east-1.amazonaws.com/dev/getredacteddocument';
    let fileNameEdited = fileName.replace('.png', '_REDACTED.png')
    axios.post(api2, { "file_name": `public/${fileNameEdited}`, "bucket_name": "pii-store" }
    )
      .then((response) => {
        //console.log(response.data.signed_URL)
        setImage(response.data.signed_URL)
      })
      .catch((error) => {
        console.log(error);
      });
  }, [fileName]);

  return (
    <AppLayout
      navigation={<ServiceNavigation />} // Navigation panel content imported from './ServiceNavigation.jsx'
      breadcrumbs={<Breadcrumbs fileName={fileName}/>} // Breadcrumbs element defined below
      content={<Content fileName={fileName} image={image} approval={approval} time={time} email={email} created={created} retrieveWorkflow={retrieveWorkflow} approved={approved}/>}
      contentType="default" // Sets default app layout settings for widths
      tools={Tools} // Tools panel content defined below
    />
  );
}

const arrayToDict = (array) =>
  array.reduce((obj, item) => {
    obj[item] = false
    return obj
  }, {})

// Breadcrumb content
const Breadcrumbs = (props) => (
  <BreadcrumbGroup
    items={[
      {
        text: 'Amazon DRS',
        href: '#/'
      },
      {
        text: 'Workflows',
        href: '#/workflow'
      },
      {
        text: `${props.fileName}`
      }
    ]}
  />
);

// Main content area (fill it in with components!)
const Content = props => {

  const [presignedUrl, setPresignedUrl] = useState('www.copypresignedurl.com')

  const ValueWithLabel = ({ label, children }) => (
    <div>
      <Box margin={{ bottom: 'xxxs' }} color="text-label">
        {label}
      </Box>
      <div>{children}</div>
    </div>
  );

  return (
    <Form
      header={<Header variant="h1">Release Document</Header>}
    >
      <SpaceBetween direction="vertical" size="l">

        <Container header={<Header variant="h2" actions={<Button iconName="refresh" variant="normal" onClick={props.retrieveWorkflow}>Refresh</Button>}>Approval Workflow</Header>}>
          <ColumnLayout columns={2} variant="text-grid">
            <SpaceBetween size="l">
              <Box variant="h3" padding="n">
                Status
        </Box>
              
              <ValueWithLabel label="Approval status">
                <StatusIndicator type={props.approval === 'REJECTED' ? 'error' : (props.approval === 'PENDING' ? 'pending' : 'success')}>{props.approval}</StatusIndicator>
              </ValueWithLabel>
              <ValueWithLabel label="Approver email">{props.email}</ValueWithLabel>
            </SpaceBetween>
            <SpaceBetween size="l">
              <Box variant="h3" padding="n">
              { props.approved ? 'Shareable Link' : 'Shareable Link (Not Available)' }
        </Box>
              <span className="awsui-util-copy-text">
                <Popover
                  size="small"
                  position="top"
                  triggerType="custom"
                  dismissButton={false}
                  content={ props.approved ? <StatusIndicator type="success">Shareable link copied</StatusIndicator> : <StatusIndicator type="error">Document not approved</StatusIndicator>}
                >
                  <Button iconName="copy" variant="icon" onClick={() => { navigator.clipboard.writeText(props.image) }} />
                </Popover>{' '}
                { props.approved ? 'Copy presigned URL' : 'No presigned URL' }
              </span>
            </SpaceBetween>
            <SpaceBetween size="l">
              <Box variant="h3" padding="n">
                Timeline
        </Box>
              <ValueWithLabel label="Last modified">{props.time}</ValueWithLabel>
              <ValueWithLabel label="Time created">{props.created}</ValueWithLabel>
            </SpaceBetween>
            <SpaceBetween size="l">
              <Box variant="h3" padding="n">
                { props.approved ? 'Download File' : 'Download File (Not Available)' }
        </Box>

              <Button variant="primary" iconName="download" href={props.image} disabled={!props.approved}>Download</Button>
            </SpaceBetween>
          </ColumnLayout>
        </Container>

        <Container header={<Header variant="h2">Document Preview</Header>} >
          <SpaceBetween size="l">
            <center>
              <img src={props.image} alt="" width="100%" />
            </center>
          </SpaceBetween>
        </Container>

      </SpaceBetween>
    </Form>
  )
}

// Help panel content
const Tools = (
  <HelpPanel
    header={<h2>CloudFront</h2>}
    footer={
      <div>
        <h3>
          Learn more <Icon name="external" />
        </h3>
        <ul>
          <li>
            <a href="http://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/Introduction.html">
              What is Amazon CloudFront?
            </a>
          </li>
          <li>
            <a href="http://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/GettingStarted.html">
              Getting started
            </a>
          </li>
          <li>
            <a href="http://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/distribution-working-with.html">
              Working with distributions
            </a>
          </li>
        </ul>
      </div>
    }
  >
    <p>
      Amazon CloudFront is a web service that speeds up distribution of your static and dynamic web content, such as
      .html, .css, .js, and image files, to your users.
    </p>
  </HelpPanel>
);
