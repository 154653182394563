/************************************************************************
                            DISCLAIMER

This is just a playground package. It does not comply with best practices
of using AWS-UI components. For production code, follow the integration
guidelines:

https://polaris.a2z.com/develop/integration/react/
************************************************************************/
import React from 'react';
import ServiceNavigation from './ServiceNavigation.jsx';
import {
  AppLayout,
  Button,
  ColumnLayout,
  FormField,
  Icon,
  Select,
  Container,
  Header,
  Box,
  Grid,
  SpaceBetween,
  Link
} from '../aws-ui-components';

import '../styles/servicehomepage.css';

// Component ServiceHomepage is a skeleton of a service's homepage using AWS-UI React components.
export default () => {
  return (
    <AppLayout
      disableContentPaddings={true}
      navigation={<ServiceNavigation />} // Navigation panel content imported from './ServiceNavigation.jsx'
      content={<Content />}
      contentType="default"
      navigationOpen={false}
      toolsHide={true}
    />
  );
};

// The content in the main content area of the App layout
const Content = () => (
  <Box padding="s" margin={{ bottom: 'l' }}>
    <Grid className="custom-home__header" gridDefinition={[{ colspan: { xxs: 12 } }]}>
      <Box padding={{ vertical: 'xxl' }}>
        <Grid
          gridDefinition={[
            { offset: { l: '2', xxs: '1' }, colspan: { l: '8', xxs: '10' } },
            { colspan: { xxs: 10, s: 6, l: 5, xl: 6 }, offset: { xxs: 1, l: 2 } },
            { colspan: { xxs: 10, s: 4, l: 3, xl: 2 }, offset: { xxs: 1, s: 0 } }
          ]}
        >
          <Box fontWeight="light" padding={{ top: 'xs' }}>
            <span className="custom-home__category">Machine Learning</span>
          </Box>

          <div className="custom-home__header-title">
            <Box variant="h1" fontWeight="bold" padding="n" fontSize="display-l" color="inherit">
              Amazon DRS
            </Box>
            <Box fontWeight="light" padding={{ bottom: 's' }} fontSize="display-l" color="inherit">
              Workflow to approve and release sensitive files
            </Box>
            <Box variant="p" fontWeight="light">
              <span className="custom-home__header-sub-title">
                Amazon DEEP Recognition Service (DRS) is a serverless workflow that leverages machine learning to understand documents for before redacting sensitive information for release.
              </span>
            </Box>
          </div>

          <Container>
            <SpaceBetween size="xl">
              <Box variant="h2" padding="n">
                Create Workflow
              </Box>
              Upload a file to get started
              <Button href="#/table" variant="primary">
                Start
              </Button>
            </SpaceBetween>
          </Container>
        </Grid>
      </Box>
    </Grid>

    <Box padding={{ top: 'xxl' }}>
      <Grid
        gridDefinition={[
          { colspan: { xxs: 10, s: 6, l: 5, xl: 6 }, offset: { xxs: 1, l: 2 } },
          { colspan: { xxs: 10, s: 4, l: 3, xl: 2 }, offset: { xxs: 1, s: 0 } }
        ]}
      >
        <div>
          <SpaceBetween size="l">

            <div>
              <Box fontSize="heading-xl" fontWeight="normal" variant="h2">
                Use cases
              </Box>
              <Container>
                <ColumnLayout columns={2} variant="text-grid">
                  <div>
                    <Box variant="h3" padding={{ top: 'n' }}>
                      Simplify workflows
                    </Box>
                    <Box variant="p">
                      Configure multiple approvers and release criteria based on sensitive information contained within your documents. Scan through names, places, organisations or add your own keywords.
                    </Box>
                    <Link href="#" external>
                      Learn more
                    </Link>
                  </div>
                  <div>
                    <Box variant="h3" padding={{ top: 'n' }}>
                      Eliminate human error
                    </Box>
                    <Box variant="p">
                      Use Amazon DRS to accurately identify sensitive information without the need to set up or operate a complex work order.
                      Amazon DRS supports multiple paramaters for data classification.
                    </Box>
                    <Link href="#" external>
                      Learn more
                    </Link>
                  </div>
                </ColumnLayout>
              </Container>
            </div>

            <Container header={<Header variant="h2">Related services</Header>}>
              <ColumnLayout columns={2} variant="text-grid">
                <div>
                  <Box variant="h3" padding={{ top: 'n' }}>
                    <Link fontSize="heading-m" external>
                      Amazon S3
                    </Link>
                  </Box>
                  <Box variant="p">Use Amazon S3 to store the documents that Amazon DRS analyses.</Box>
                </div>
                <div>
                  <Box variant="h3" padding={{ top: 'n' }}>
                    <Link fontSize="heading-m" external>
                      Amazon Comprehend
                    </Link>
                  </Box>
                  <Box variant="p">
                    Use Amazon Comprehend to generate meaning from your documents.
                  </Box>
                </div>
              </ColumnLayout>
            </Container>
          </SpaceBetween>
        </div>

        <div className="custom-home__sidebar">
          <SpaceBetween size="xxl">
            <Container
              header={
                <Header variant="h2">
                  Getting started <Icon name="external" />
                </Header>
              }
            >
              <ul className="custom-list-separator">
                <li>
                  <Link
                    href="https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/Introduction.html"
                    target="_blank"
                  >
                    What is Amazon DRS?
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/GettingStarted.html"
                    target="_blank"
                  >
                    Getting started with Amazon DRS
                  </Link>
                </li>
                <li>
                  <Link
                    href="https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/distribution-working-with.html"
                    target="_blank"
                  >
                    Working with Amazon DRS redactions
                  </Link>
                </li>
              </ul>
            </Container>
            <Container
              header={
                <Header variant="h2">
                  More resources <Icon name="external" />
                </Header>
              }
            >
              <ul className="custom-list-separator">
                <li>
                  <Link href="https://aws.amazon.com/documentation/cloudfront/" target="_blank">
                    Documentation
                  </Link>
                </li>
                <li>
                  <Link href="#">FAQ</Link>
                </li>
                <li>
                  <Link href="#">DRS forum</Link>
                </li>
                <li>
                  <Link href="#">Contact us</Link>
                </li>
              </ul>
            </Container>
          </SpaceBetween>
        </div>
      </Grid>
    </Box>
  </Box>
);
