/************************************************************************
                            DISCLAIMER

This is just a playground package. It does not comply with best practices
of using AWS-UI components. For production code, follow the integration
guidelines:

https://polaris.a2z.com/develop/integration/react/
************************************************************************/
import React, { useState, useEffect } from 'react';
import DataProvider from '../resources/data-provider';
import ServiceNavigation from './ServiceNavigation.jsx';
import {
  COLUMN_DEFINITIONS,
  CONTENT_SELECTOR_OPTIONS,
  PAGE_SELECTOR_OPTIONS,
  CUSTOM_PREFERENCE_OPTIONS,
  DEFAULT_PREFERENCES
} from '../resources/table/table-config.jsx';
import {
  AppLayout,
  Header,
  SpaceBetween,
  Box,
  BreadcrumbGroup,
  Button,
  Flashbar,
  Form,
  CollectionPreferences,
  Pagination,
  TextFilter,
  Table,
  FormField,
  RadioGroup,
  HelpPanel,
  Icon
} from '../aws-ui-components';
import './Table.css'
import { v4 as uuid } from 'uuid'
import { useCollection } from '@awsui/collection-hooks';
import Amplify, { Auth, Storage } from 'aws-amplify';
import awsconfig from './aws-exports';
import axios from 'axios';
Amplify.configure(awsconfig);
Auth.configure(awsconfig);

// Component TableView is a skeleton of a Table using AWS-UI React components.
export default () => {
  return (
    <AppLayout
      navigation={<ServiceNavigation />} // Navigation panel content imported from './ServiceNavigation.jsx'
      //notifications={<FlashMessage />}
      breadcrumbs={<Breadcrumbs />}
      content={<DetailsTable />}
      contentType="table"
      tools={Tools}
    />
  );
};

const DetailsTable = () => {
  // Below are variables declared to maintain the table's state.
  // Each declaration returns two values: the first value is the current state, and the second value is the function that updates it.
  // They use the general format: [x, setX] = useState(defaultX), where x is the attribute you want to keep track of.
  // For more info about state variables and hooks, see https://reactjs.org/docs/hooks-state.html.
  const [distributions, setDistributions] = useState([]);
  const [selectedDistributions, setSelectedDistributions] = useState([]);
  const [preferences, setPreferences] = useState(DEFAULT_PREFERENCES);
  const [loading, setLoading] = useState(true);

  // a utility to handle operations on the data set (such as filtering, sorting and pagination)
  // https://polaris.a2z.com/develop/guides/collection_hooks/
  const { items, actions, collectionProps, filterProps, paginationProps, filteredItemsCount } = useCollection(
    distributions,
    {
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
      filtering: {
        noMatch: (
          <Box textAlign="center" color="inherit">
            <b>No matches</b>
            <Box color="inherit" margin={{ top: 'xxs', bottom: 's' }}>
              No results match your query
            </Box>
            <Button onClick={() => actions.setFiltering('')}>Clear filter</Button>
          </Box>
        )
      }
    }
  );

  async function fetchImages() {
    let s3images = await Storage.list('')
    setDistributions(s3images);
    console.log(s3images)
    setLoading(false)
  }

  // fetch distributions after render of the component
  useEffect(() => {
    fetchImages()
  }, []);

  // Keeps track of how many distributions are selected
  function headerCounter(selectedDistributions, distributions) {
    return selectedDistributions.length
      ? `(${selectedDistributions.length} of ${distributions.length})`
      : `(${distributions.length})`;
  }

  function filterCounter(count) {
    return `${count} ${count === 1 ? 'match' : 'matches'}`;
  }

  return (
    <Form
      header={<Header variant="h1">Documents</Header>}
    >
      <Table
        {...collectionProps}
        columnDefinitions={COLUMN_DEFINITIONS}
        visibleColumns={preferences.visibleContent}
        items={items}
        loading={loading}
        loadingText="Loading resources"
        header={
          <TableHeader
            fetchImages={fetchImages}
            selectedDistributions={selectedDistributions}
            counter={headerCounter(selectedDistributions, distributions)}
          />
        }
        preferences={
          <CollectionPreferences
            title="Preferences"
            confirmLabel="Confirm"
            cancelLabel="Cancel"
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={{
              title: 'Page size',
              options: PAGE_SELECTOR_OPTIONS
            }}
            visibleContentPreference={{
              title: 'Select visible columns',
              options: CONTENT_SELECTOR_OPTIONS
            }}
            wrapLinesPreference={{
              label: 'Wrap lines',
              description: 'Check to see all the text and wrap the lines'
            }}
            customPreference={(value, setValue) => (
              <FormField stretch={true} label="View as">
                <RadioGroup
                  value={value}
                  onChange={({ detail }) => setValue(detail.value)}
                  items={CUSTOM_PREFERENCE_OPTIONS}
                />
              </FormField>
            )}
          />
        }
        wrapLines={preferences.wrapLines}
        selectedItems={selectedDistributions}
        onSelectionChange={({ detail }) => setSelectedDistributions(detail.selectedItems)}
        selectionType="multi"
        pagination={<Pagination {...paginationProps} />}
        filter={
          <TextFilter
            {...filterProps}
            countText={filterCounter(filteredItemsCount)}
            filteringPlaceholder="Search files"
          />
        }
      />
    </Form>
  );
};

// Table header content, shows how many distributions are selected and contains the action stripe
const TableHeader = ({ fetchImages, selectedDistributions, counter }) => {
  const isOnlyOneSelected = selectedDistributions.length === 1;

  const [upload, setUpload] = useState({ fileInfo: "", name: "Choose File" })
  const [loading, setLoading] = useState(false)

  const updateFileName = (fileName) => {
    let fileNameSubstring = fileName.slice(0, -4);
    //fileNameSubstring = `${fileNameSubstring}-${uuid()}`
    fileNameSubstring = fileNameSubstring.concat('.png')
    fileNameSubstring = fileNameSubstring.split(' ').join('_')
    return fileNameSubstring
  }

  function onChangeFile(e) {
    e.persist();
    if (!e.target.files[0]) return;
    const image = { fileInfo: e.target.files[0], name: updateFileName(e.target.files[0].name) }
    setUpload(image)
  }

  async function save() {
    try {
      if (upload.length === 0) {
        console.log("No File Selected to Upload")
        return;
      }
      setLoading(true)
      await Storage.put(upload.name, upload.fileInfo, {
        progressCallback(progress) {
          console.log(`Uploaded percentage: ${progress.loaded}/${progress.total}`);
        }
      }).then(() => fetchImages())
      const uploadState = { fileInfo: "", name: "Choose File" }
      setUpload(uploadState)
      setLoading(false)

    } catch (err) {
      console.log('error: ', err);
    }
  }

  const handleDelete = () => {
    console.log(selectedDistributions)
    const api = 'https://3v6vw2ilwh.execute-api.us-east-1.amazonaws.com/dev/delete';
    axios.post(api, { "file_name": `public/${selectedDistributions[0].key}`, "bucket_name": "techu-capstone-drs-raw-images230053-dev"}
    )
      .then((response) => {
        console.log(response)
      })
      .then(() => fetchImages())
      .catch((error) => {
        console.log(error);
      });

  }

  return (
    <Header
      variant="h2"
      counter={counter}
      actions={
        <SpaceBetween direction="horizontal" size="s">
          <Button disabled={selectedDistributions.length === 0} disabled={!isOnlyOneSelected} onClick={handleDelete}>Delete</Button>
          <label className="_button_1npim_7 _variant-normal_1npim_27">
            <input type="file" onChange={onChangeFile} accept='image/png' />
            {upload.name}
          </label>
          <Button variant="primary" onClick={save} loading={loading} iconName="upload">
            Upload
          </Button>
        </SpaceBetween>
      }
    >
      Files
    </Header>
  );
};

// Breadcrumb content
const Breadcrumbs = () => (
  <BreadcrumbGroup
    items={[
      {
        text: 'Amazon DRS',
        href: '#/'
      },
      {
        text: 'Documents',
        href: '#/table'
      }
    ]}
  />
);

// Flash message content
/*
const FlashMessage = () => {
  const [items, setItems] = useState([
    {
      type: 'success',
      dismissible: true,
      onDismiss: () => setItems([]),
      content: 'Resource created successfully'
    }
  ]);
  return <Flashbar items={items} />;
};
*/
// Help (right) panel content
const Tools = [
  <HelpPanel
    header={<h2>Distributions</h2>}
    footer={
      <div>
        <h3>
          Learn more <Icon name="external" />
        </h3>
        <ul>
          <li>
            <a href="https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/distribution-working-with.html">
              Working with distributions
            </a>
          </li>
          <li>
            <a href="https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/distribution-web-values-returned.html">
              Values that CloudFront displays on the console
            </a>
          </li>
        </ul>
      </div>
    }
  >
    <p>
      View your current distributions and related information such as the associated domain names, delivery methods, SSL
      certificates, and more. To drill down even further into the details, choose the name of an individual
      distribution.
    </p>
  </HelpPanel>
];
