/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:2660ed61-45c5-4031-91f9-da526dec6611",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_pSvD7gnRS",
    "aws_user_pools_web_client_id": "6157o7a450p58ajb3tfrcbqcr9",
    "oauth": {},
    "aws_user_files_s3_bucket": "techu-capstone-drs-raw-images230053-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
