/************************************************************************
                            DISCLAIMER

This is just a playground package. It does not comply with best practices
of using AWS-UI components. For production code, follow the integration
guidelines:

https://polaris.a2z.com/develop/integration/react/
************************************************************************/
import React, { useState, useEffect } from 'react';
import DataProvider from '../resources/data-provider';
import ServiceNavigation from './ServiceNavigation.jsx';
import {
  COLUMN_DEFINITIONS,
  CONTENT_SELECTOR_OPTIONS,
  PAGE_SELECTOR_OPTIONS,
  CUSTOM_PREFERENCE_OPTIONS,
  DEFAULT_PREFERENCES
} from '../resources/table/workflow-table-config.jsx';
import {
  AppLayout,
  Header,
  SpaceBetween,
  Box,
  BreadcrumbGroup,
  Button,
  Flashbar,
  Form,
  CollectionPreferences,
  Pagination,
  TextFilter,
  Table,
  FormField,
  RadioGroup,
  HelpPanel,
  Icon
} from '../aws-ui-components';
import axios from 'axios';

import { useCollection } from '@awsui/collection-hooks';
import Amplify, { Auth, Storage } from 'aws-amplify';
import awsconfig from './aws-exports';
Amplify.configure(awsconfig);
Auth.configure(awsconfig);

// Component TableView is a skeleton of a Table using AWS-UI React components.
export default () => {
  return (
    <AppLayout
      navigation={<ServiceNavigation />} // Navigation panel content imported from './ServiceNavigation.jsx'
      //notifications={<FlashMessage />}
      breadcrumbs={<Breadcrumbs />}
      content={<DetailsTable />}
      contentType="table"
      tools={Tools}
    />
  );
};

const DetailsTable = () => {
  // Below are variables declared to maintain the table's state.
  // Each declaration returns two values: the first value is the current state, and the second value is the function that updates it.
  // They use the general format: [x, setX] = useState(defaultX), where x is the attribute you want to keep track of.
  // For more info about state variables and hooks, see https://reactjs.org/docs/hooks-state.html.
  const [distributions, setDistributions] = useState([]);
  const [selectedDistributions, setSelectedDistributions] = useState([]);
  const [preferences, setPreferences] = useState(DEFAULT_PREFERENCES);
  const [loading, setLoading] = useState(true);

  // a utility to handle operations on the data set (such as filtering, sorting and pagination)
  // https://polaris.a2z.com/develop/guides/collection_hooks/
  const { items, actions, collectionProps, filterProps, paginationProps, filteredItemsCount } = useCollection(
    distributions,
    {
      pagination: { pageSize: preferences.pageSize },
      sorting: {},
      filtering: {
        noMatch: (
          <Box textAlign="center" color="inherit">
            <b>No matches</b>
            <Box color="inherit" margin={{ top: 'xxs', bottom: 's' }}>
              No results match your query
            </Box>
            <Button onClick={() => actions.setFiltering('')}>Clear filter</Button>
          </Box>
        )
      }
    }
  );

  async function fetchImages() {
    let s3images = await Storage.list('')
    //setDistributions(s3images);
    //console.log(s3images)
    //setLoading(false)
  }

  async function fetchWorkflows() {
    const api = 'https://3v6vw2ilwh.execute-api.us-east-1.amazonaws.com/dev/scantable'
    axios.get(api)
      .then((response) => {
        console.log('hello')
        let data = response.data.Items
        let workflowList = []
        console.log(data)
        const keys = Object.keys(data)
        for (const key of keys) {
          console.log(data[key])
          if('approval' in data[key]) {
            data[key]['filename'].S = data[key]['filename'].S.replace('public/','')
            workflowList.push(data[key])
          }
        }
        console.log(workflowList)
        setDistributions(workflowList);
        setLoading(false)
      })
      .catch((error) => {
        console.log(error);
      });
  }

  // fetch distributions after render of the component
  useEffect(() => {
    fetchImages()
    fetchWorkflows()
  }, []);

  // Keeps track of how many distributions are selected
  function headerCounter(selectedDistributions, distributions) {
    return selectedDistributions.length
      ? `(${selectedDistributions.length} of ${distributions.length})`
      : `(${distributions.length})`;
  }

  function filterCounter(count) {
    return `${count} ${count === 1 ? 'match' : 'matches'}`;
  }

  return (
    <Form
      header={<Header variant="h1">Workflows</Header>}
    >
      <Table
        {...collectionProps}
        columnDefinitions={COLUMN_DEFINITIONS}
        visibleColumns={preferences.visibleContent}
        items={items}
        loading={loading}
        loadingText="Loading resources"
        header={
          <TableHeader
            selectedDistributions={selectedDistributions}
            counter={headerCounter(selectedDistributions, distributions)}
          />
        }
        preferences={
          <CollectionPreferences
            title="Preferences"
            confirmLabel="Confirm"
            cancelLabel="Cancel"
            preferences={preferences}
            onConfirm={({ detail }) => setPreferences(detail)}
            pageSizePreference={{
              title: 'Page size',
              options: PAGE_SELECTOR_OPTIONS
            }}
            visibleContentPreference={{
              title: 'Select visible columns',
              options: CONTENT_SELECTOR_OPTIONS
            }}
            wrapLinesPreference={{
              label: 'Wrap lines',
              description: 'Check to see all the text and wrap the lines'
            }}
            customPreference={(value, setValue) => (
              <FormField stretch={true} label="View as">
                <RadioGroup
                  value={value}
                  onChange={({ detail }) => setValue(detail.value)}
                  items={CUSTOM_PREFERENCE_OPTIONS}
                />
              </FormField>
            )}
          />
        }
        wrapLines={preferences.wrapLines}
        selectedItems={selectedDistributions}
        onSelectionChange={({ detail }) => setSelectedDistributions(detail.selectedItems)}
        selectionType="multi"
        pagination={<Pagination {...paginationProps} />}
        filter={
          <TextFilter
            {...filterProps}
            countText={filterCounter(filteredItemsCount)}
            filteringPlaceholder="Search files"
          />
        }
      />
    </Form>
  );
};

// Table header content, shows how many distributions are selected and contains the action stripe
const TableHeader = ({ selectedDistributions, counter }) => {
  const isOnlyOneSelected = selectedDistributions.length === 1;

  return (
    <Header
      variant="h2"
      counter={counter}
    >
      Files
    </Header>
  );
};

// Breadcrumb content
const Breadcrumbs = () => (
  <BreadcrumbGroup
    items={[
      {
        text: 'Amazon DRS',
        href: '#/'
      },
      {
        text: 'Workflows',
        href: '#/workflow'
      }
    ]}
  />
);

// Flash message content
/*
const FlashMessage = () => {
  const [items, setItems] = useState([
    {
      type: 'success',
      dismissible: true,
      onDismiss: () => setItems([]),
      content: 'Resource created successfully'
    }
  ]);
  return <Flashbar items={items} />;
};
*/
// Help (right) panel content
const Tools = [
  <HelpPanel
    header={<h2>Distributions</h2>}
    footer={
      <div>
        <h3>
          Learn more <Icon name="external" />
        </h3>
        <ul>
          <li>
            <a href="https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/distribution-working-with.html">
              Working with distributions
            </a>
          </li>
          <li>
            <a href="https://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/distribution-web-values-returned.html">
              Values that CloudFront displays on the console
            </a>
          </li>
        </ul>
      </div>
    }
  >
    <p>
      View your current distributions and related information such as the associated domain names, delivery methods, SSL
      certificates, and more. To drill down even further into the details, choose the name of an individual
      distribution.
    </p>
  </HelpPanel>
];
