import React from 'react';
//import { Link, StatusIndicator } from '../../aws-ui-components';
import { Link } from "react-router-dom";
import './table-config.css';


export const COLUMN_DEFINITIONS = [
  {
    id: 'key',
    header: 'File Name',
    cell: item => <Link className="link" to={`table/${item.key}`}>{item.key}</Link>,
    minWidth: '180px',
    sortingField: 'key'
  },
  {
    id: 'size',
    header: 'File Size (MB)',
    cell: item => (item.size/1048576).toFixed(2),
    minWidth: '100px',
    sortingField: 'size'
  },
  {
    id: 'lastModified',
    header: 'Last Modified',
    cell: item => item.lastModified.toISOString().split('T').join(' ').split('.000Z'),
    minWidth: '100px',
    maxWidth: '200px',
    sortingField: 'lastModified'
  },
  /*{
    id: 'state',
    header: 'State',
    cell: item => (
      <StatusIndicator type={item.state === 'Disabled' ? 'error' : 'success'}> {item.state}</StatusIndicator>
    ),
    minWidth: '100px',
    sortingField: 'state'
  },*/
];

export const CONTENT_SELECTOR_OPTIONS = [
  {
    label: 'Main distribution properties',
    options: [
      { id: 'id', label: 'Distribution ID', editable: false },
      { id: 'domainName', label: 'Domain name', editable: true },
      {
        id: 'deliveryMethod',
        label: 'Delivery method',
        editable: true
      },
      {
        id: 'priceClass',
        label: 'Price class',
        editable: true
      },
      {
        id: 'sslCertificate',
        label: 'SSL certificate',
        editable: true
      },
      { id: 'origin', label: 'Origin', editable: true },
      { id: 'status', label: 'Status', editable: true },
      { id: 'state', label: 'State', editable: true },
      { id: 'logging', label: 'Logging', editable: true }
    ]
  }
];

export const PAGE_SELECTOR_OPTIONS = [
  { value: 10, label: '10 Distributions' },
  { value: 30, label: '30 Distributions' },
  { value: 50, label: '50 Distributions' }
];

export const CUSTOM_PREFERENCE_OPTIONS = [{ value: 'table', label: 'Table' }, { value: 'cards', label: 'Cards' }];

export const DEFAULT_PREFERENCES = {
  pageSize: 30,
  visibleContent: ['key', 'size', 'lastModified', 'sslCertificate', 'status', 'state'],
  wraplines: false,
  custom: CUSTOM_PREFERENCE_OPTIONS[0].value
};
