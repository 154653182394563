/************************************************************************
                            DISCLAIMER

This is just a playground package. It does not comply with best practices
of using AWS-UI components. For production code, follow the integration
guidelines:

 https://polaris.a2z.com/develop/integration/react/
************************************************************************/
import React, { useState, useEffect } from 'react';
import { Storage } from 'aws-amplify'
import ServiceNavigation from './ServiceNavigation.jsx';
import {
  AppLayout,
  AttributeEditor,
  BreadcrumbGroup,
  Button,
  Checkbox,
  ColumnLayout,
  ExpandableSection,
  Form,
  FormField,
  Input,
  Multiselect,
  RadioGroup,
  Select,
  Textarea,
  Tiles,
  Header,
  SpaceBetween,
  Container,
  Link,
  HelpPanel,
  Box,
  Icon
} from '../aws-ui-components';
import axios from 'axios';

import '../styles/form.css';

// Component Basic is a skeleton of the basic App layout using AWS-UI React components.
export default function Basic({ match }) {

  let fileName = match.params.key

  const [image, setImage] = useState([''])
  const [category, setCategory] = React.useState([]);
  const [checkboxState, setCheckboxState] = React.useState({ "LOADING...": false });
  const [checkboxDisabledState, setCheckboxDisabledState] = React.useState({ "LOADING...": false });
  const [loading, setLoading] = React.useState(false);
  const [success, setSuccess] = React.useState(false);

  useEffect(() => {
    async function fetchImage() {
      const signedImage = await Storage.get(fileName)
      setImage(signedImage)
      console.log(signedImage)
    }
    fetchImage()
  }, [match])

  useEffect(() => {
    let categories = []
    const api = 'https://3v6vw2ilwh.execute-api.us-east-1.amazonaws.com/dev/getall';
    axios.post(api, { "file_name": `public/${fileName}` }
    )
      .then((response) => {
        var x = response.data["Items"][0]["categories"]["SS"].length
        for (var i = 0; i < x; i++) {
          let y = response.data["Items"][0]["categories"]["SS"][i]
          categories.push(y)
        }
        console.log(categories)
        setCategory(categories)
        const initialCheckboxState = arrayToDict(categories)
        console.log(initialCheckboxState)
        setCheckboxState(initialCheckboxState)
        setCheckboxDisabledState(initialCheckboxState)

      })
      .catch((error) => {
        console.log(error);
      });
  }, [fileName]);

  return (
    <AppLayout
      navigation={<ServiceNavigation />} // Navigation panel content imported from './ServiceNavigation.jsx'
      breadcrumbs={<Breadcrumbs fileName={fileName} />} // Breadcrumbs element defined below
      content={<Content fileName={fileName} image={image} setImage={setImage} category={category} checkboxState={checkboxState} setCheckboxState={setCheckboxState} checkboxDisabledState={checkboxDisabledState} loading={loading} setLoading={setLoading} success={success} setSuccess={setSuccess} />}
      contentType="default" // Sets default app layout settings for widths
      tools={Tools} // Tools panel content defined below
    />
  );
}

const arrayToDict = (array) =>
  array.reduce((obj, item) => {
    obj[item] = false
    return obj
  }, {})

// Breadcrumb content
const Breadcrumbs = (props) => (
  <BreadcrumbGroup
    items={[
      {
        text: 'Amazon DRS',
        href: '#/'
      },
      {
        text: 'Documents',
        href: '#/table'
      },
      {
        text: props.fileName
      }
    ]}
  />
);

// Main content area (fill it in with components!)
const Content = props => {

  const [value, setValue] = useState()

  const handleRedact = () => {
    props.setLoading(true);
    const checkboxStateKeys = Object.keys(props.checkboxState)
    let checkboxStateEvaluated = []
    for (const keys of checkboxStateKeys) {
      if (props.checkboxState[keys]) checkboxStateEvaluated.push(keys)
    }
    console.log(checkboxStateEvaluated)

    const api2 = 'https://3v6vw2ilwh.execute-api.us-east-1.amazonaws.com/dev/redact'
    console.log('we about to go into function 2')
    console.log(JSON.stringify(checkboxStateEvaluated))
    console.log(JSON.stringify({ "file_name": `public/${props.fileName}`, "categories": checkboxStateEvaluated, "bucket_name": "techu-capstone-drs-raw-images230053-dev" }))

    axios.post(api2, { "file_name": `public/${props.fileName}`, "categories": JSON.stringify(checkboxStateEvaluated), "bucket_name": "techu-capstone-drs-raw-images230053-dev" })
      .then((response2) => {
        console.log('hello')
        console.log(response2.data)
        const file = response2.data["signed_URL"]
        console.log('the file we want to return')

        console.log(file)
        props.setImage(file)

        props.setSuccess(true);
        props.setLoading(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const handleWorkflow = () => {

    const api = `https://7bewneqxca.execute-api.us-east-1.amazonaws.com/Dev/sendemail?document=${props.image}&email=${value}&bucket_name=techu-capstone-drs-raw-images230053-dev&filename=public/${props.fileName}`
    console.log(props.image)
    console.log('we about to go into function 3')
    //console.log(JSON.stringify({ "filename": `public/${props.fileName}`, "name": "Bob", "document": props.image, "email": "mikechau@amazon.com", "bucket_name": "techu-capstone-drs-raw-images230053-dev" }))
    let body = {"filename": `public/${props.fileName}`, "document": props.image, "email": value, "bucket_name": "techu-capstone-drs-raw-images230053-dev"}
    console.log(JSON.stringify(body))
    axios.put(api, body)
      .then((response) => {
        console.log('hello')
        console.log(response)
      })
      .catch((error) => {
        console.log(error);
      });

  }

  const handleCommercialItem = (event) => {
    props.setCheckboxState({ ...props.checkboxState, "COMMERCIAL_ITEM": event.detail.checked });
  };

  const handleDate = (event) => {
    props.setCheckboxState({ ...props.checkboxState, "DATE": event.detail.checked });
  };

  const handleEvent = (event) => {
    props.setCheckboxState({ ...props.checkboxState, "EVENT": event.detail.checked });
  };

  const handleLocation = (event) => {
    props.setCheckboxState({ ...props.checkboxState, "LOCATION": event.detail.checked });
  };

  const handleOrganization = (event) => {
    props.setCheckboxState({ ...props.checkboxState, "ORGANIZATION": event.detail.checked });
  };

  const handleOther = (event) => {
    props.setCheckboxState({ ...props.checkboxState, "OTHER": event.detail.checked });
  };

  const handlePerson = (event) => {
    props.setCheckboxState({ ...props.checkboxState, "PERSON": event.detail.checked });
  };

  const handleQuantity = (event) => {
    props.setCheckboxState({ ...props.checkboxState, "QUANTITY": event.detail.checked });
  };

  const handleTitle = (event) => {
    props.setCheckboxState({ ...props.checkboxState, "TITLE": event.detail.checked });
  };

  return (
    <Form
      header={<Header variant="h1">Redact document</Header>}
      actions={
        // located at the bottom of the form
        <SpaceBetween direction="horizontal" size="xs">
          <Button variant="link" href="#/table">Cancel</Button>
          <Button onClick={handleWorkflow} variant="primary" href="#/workflow" disabled={!props.success}>
            Create Workflow
          </Button>
        </SpaceBetween>
      }
    >
      <SpaceBetween direction="vertical" size="l">

        <Container header={<Header variant="h2">Document Preview</Header>} >
          <SpaceBetween size="l">
            <center>
              <img src={props.image} alt="" width="100%" />
            </center>
          </SpaceBetween>
        </Container>

        <Container header={<Header variant="h2">Document Redaction</Header>}>
          <SpaceBetween size="l">
            <FormField label="Redaction categories" stretch={true}>
              <Checkbox
                checked={props.checkboxState.COMMERCIAL_ITEM}
                disabled={!(props.checkboxDisabledState.COMMERCIAL_ITEM === false)}
                onChange={handleCommercialItem}
                description="A branded product">
                Commercial Item
              </Checkbox>
              <Checkbox
                checked={props.checkboxState.DATE}
                disabled={!(props.checkboxDisabledState.DATE === false)}
                onChange={handleDate}
                description="A full date (for example, 11/25/2017), day (Tuesday), month (May), or time (8:30 a.m.)">
                Date
              </Checkbox>
              <Checkbox
                checked={props.checkboxState.EVENT}
                disabled={!(props.checkboxDisabledState.EVENT === false)}
                onChange={handleEvent}
                description="An event, such as a festival, concert, election, etc.">
                Event
              </Checkbox>
              <Checkbox
                checked={props.checkboxState.LOCATION}
                disabled={!(props.checkboxDisabledState.LOCATION === false)}
                onChange={handleLocation}
                description="A specific location, such as a country, city, lake, building, etc.">
                Location
              </Checkbox>
              <Checkbox
                checked={props.checkboxState.ORGANIZATION}
                disabled={!(props.checkboxDisabledState.ORGANIZATION === false)}
                onChange={handleOrganization}
                description="Large organizations, such as a government, company, religion, sports team, etc.">
                Organization
              </Checkbox>
              <Checkbox
                checked={props.checkboxState.OTHER}
                disabled={!(props.checkboxDisabledState.OTHER === false)}
                onChange={handleOther}
                description="Entities that don't fit into any of the other entity categories">
                Other
              </Checkbox>
              <Checkbox
                checked={props.checkboxState.PERSON}
                disabled={!(props.checkboxDisabledState.PERSON === false)}
                onChange={handlePerson}
                description="Individuals, groups of people, nicknames, fictional characters">
                Person
              </Checkbox>
              <Checkbox
                checked={props.checkboxState.QUANTITY}
                disabled={!(props.checkboxDisabledState.QUANTITY === false)}
                onChange={handleQuantity}
                description="A quantified amount, such as currency, percentages, numbers, bytes, etc.">
                Quantity
              </Checkbox>
              <Checkbox
                checked={props.checkboxState.TITLE}
                disabled={!(props.checkboxDisabledState.TITLE === false)}
                onChange={handleTitle}
                description="An official name given to any creation or creative work, such as movies, books, songs, etc.">
                Title
              </Checkbox>
            </FormField>
            <Button onClick={handleRedact} loading={props.loading}>Preview Redaction</Button>
          </SpaceBetween>
        </Container>

        <Container header={<Header variant="h2">Manager Approval</Header>}>
          <SpaceBetween size="l">
            <FormField
              label="Manager email"
              description="The email to approve document before release."
            >
              <Input
                placeholder="Approver email"
                onChange={({ detail }) => setValue(detail.value)}
                value={value}
              />
            </FormField>
          </SpaceBetween>
        </Container>

      </SpaceBetween>
    </Form>
  )
}

// Help panel content
const Tools = (
  <HelpPanel
    header={<h2>CloudFront</h2>}
    footer={
      <div>
        <h3>
          Learn more <Icon name="external" />
        </h3>
        <ul>
          <li>
            <a href="http://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/Introduction.html">
              What is Amazon CloudFront?
            </a>
          </li>
          <li>
            <a href="http://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/GettingStarted.html">
              Getting started
            </a>
          </li>
          <li>
            <a href="http://docs.aws.amazon.com/AmazonCloudFront/latest/DeveloperGuide/distribution-working-with.html">
              Working with distributions
            </a>
          </li>
        </ul>
      </div>
    }
  >
    <p>
      Amazon CloudFront is a web service that speeds up distribution of your static and dynamic web content, such as
      .html, .css, .js, and image files, to your users.
    </p>
  </HelpPanel>
);
